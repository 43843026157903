export default class EventManager {

	static instance = new EventManager();

	constructor() {
		this.events = {};
	}

	on(eventName, callback, context) {
		if (!this.events[eventName]) {
			this.events[eventName] = [];
		}
		const listener = {
			callback,
			context
		};
		this.events[eventName].push(listener);
		return listener;
	}

	off(eventName, listenerToRemove) {
		if (!this.events[eventName]) {
			return;
		}
		this.events[eventName] = this.events[eventName].filter(listener => listener !== listenerToRemove);
	}

	emit(eventName, data) {
		if (!this.events[eventName]) {
			return;
		}
		this.events[eventName].forEach(listener => {
			listener.callback.call(listener.context, data);
		});
	}
}