function getChart(index, rechangrData) {

	var color = ["#00FFFC", "#FFC400", "#99D155", "#1AD979", "#3D99E8", "#FF8D3A"];

	let titles = ['各档位付费金额比例（单位：rmb）', '各档位付费次数比例（单位：rmb）', '累计充值比例（单位：rmb）'];

	let names = ['付费金额', '付费次数', '付费总额'];

	let datas = rechangrData.list

	const rich = {
		title: {
			color: '#000000',
			fontSize: 17,
		},
		titleUnit: {
			color: '#000000',
			fontSize: 16,
			padding: [10, 0, 0, 0],
		},
		titleValue: {
			color: 'rgba(0, 0, 0, 1.0)',
			fontSize: 24,
			fontWeight: 700,
			padding: [15, 5, 5, 5],
		},
	};

	const title = {
		name: names[index],
		value: rechangrData.value,
		unit: index == 0 ? '¥' : '',
	};

	let option = {
		tooltip: {
			trigger: 'item',
			formatter: function(params) {
				return `${params.name}<br/>占比: ${params.value}%<br/>数量: ${params.data.customField}`;
			}
		},
		color,
		title: {
			text: titles[index],
			x: 'center',
			y: 50
		},
		legend: {
			top: '50%',
			left: '60%',
			type: 'scroll',
			orient: 'vertical',
			data: datas,
			formatter(name) {
				const item = datas.filter((item) => item.name === name)[0];
				return name + ': ' + item.value + "%	" + (index == 0 ? "¥" : "") + item.customField;
			},
			textStyle: {
				rich: {
					name: {
						color: '#000000',
						fontSize: 14,
						width: 100,
					},
					value1: {
						color: '#000000',
						fontSize: 14,
						width: 66,
					},
				},
			},
		},
		series: [{
			name: '全网调控情况',
			type: 'pie',
			center: ['35%', '50%'],
			radius: ['40%', '50%'],
			clockwise: false, //饼图的扇区是否是顺时针排布
			avoidLabelOverlap: false,
			labelLine: {
				normal: {
					length: 15,
					length2: 13,
					smooth: true,
				}
			},
			label: {
				show: true,
				position: 'center',
				formatter: () =>
					`{title|${title.name}}\n{titleValue|${title.value}}{titleUnit|${title.unit}}`,
				rich,
			},
			data: datas
		}]
	};


	return option;
}

export default {
	getChart
}