<template>
	<div class="login">
		<div class="left">
			<img src="../assets/Logo.png" />
			<img src="../assets/Icon.png" />
		</div>
		<div class="right">
			<div class="content">
				<h3>账户密码登录</h3>
				<el-input placeholder="请输入账号" v-model="code"></el-input>
				<el-input placeholder="请输入密码" v-model="pass_word" type="passWord"></el-input>
				<el-button @click="clickLogin">立即登录</el-button>
			</div>
		</div>


		<el-dialog title="选择游戏" :visible.sync="dialogVisible" width="30%" class="gameView" :show-close="false"
			:close-on-click-modal="false" :close-on-press-escape="false">

			<div>游戏</div>
			<el-select v-model="sreach_form.app_name" placeholder="请选择游戏" @change="changeAppName">
				<el-option v-for="(item,index) in sreach_form.app_name_range" :key="index" :label="item.lbl"
					:value="item.lbl"></el-option>
			</el-select>

			<div>平台</div>
			<el-select v-model="sreach_form.app_platform" placeholder="请选择平台">
				<el-option v-for="(item,index) in sreach_form.app_platform_range" :key="index" :label="item.lbl"
					:value="item.lbl"></el-option>
			</el-select>

			<div>渠道</div>
			<el-select v-model="sreach_form.app_channel" placeholder="请选择渠道">
				<el-option v-for="(item,index) in sreach_form.app_channel_range" :key="index" :label="item.lbl"
					:value="item.lbl"></el-option>
			</el-select>

			<div>
				<el-button style="width: 100%;background-color: #ffa526;border: unset;" type="primary"
					@click="clickSetGame">确定</el-button>
			</div>

		</el-dialog>


	</div>
</template>

<script>
	import protocol from '../js/protocol.js'

	export default {
		name: 'login',
		data() {
			return {
				code: "",
				pass_word: "",
				dialogVisible: false,

				sreach_form: {
					app_name: "",
					app_channel: "",
					app_platform: "",
					server: "",
					server_range: [],
					start_date: "",
					end_date: "",
					app_name_range: [],
					app_channel_range: [],
					app_platform_range: [],
				},

			}
		},

		mounted() {

		},

		destroyed() {

		},

		methods: {

			async clickLogin() {

				let res = {
					user_name: this.code,
					password: this.pass_word
				};

				let ret = await this.$http.post(protocol.login, res);

				if (ret) {

					localStorage.setItem('app_permission', ret.app_permission);
					this.$http.setLoginToken(ret.token);

					await this.$userData.initAppRand();

					let curAppData = localStorage.getItem("curAppData");
					if (curAppData != null) {

						this.$router.push({
							path: "/home"
						});
					} else {

						let data = await this.$userData.getSelectRange();

						this.sreach_form.app_name_range = data.app_name_range;
						this.sreach_form.app_channel_range = data.app_channel_range;
						this.sreach_form.app_platform_range = data.app_platform_range;

						this.dialogVisible = true;
					}
				}
			},

			clickSetGame() {
				let isSet = false;
				for (let i = 0; i < this.$userData.app_list.length; i++) {
					let appData = this.$userData.app_list[i];
					if (this.sreach_form.app_name == appData.app_name && this.sreach_form.app_channel == appData
						.app_channel && this.sreach_form.app_platform == appData
						.app_platform) {
						this.$userData.saveCurAppData(appData);
						isSet = true;
					}
				}

				if (!isSet) {
					this.$message({
						message: "未找到，对于游戏ID",
						type: 'error',
						duration: 1500
					});
					return;
				}

				this.$router.push({
					path: "/home"
				});
			},


			changeAppName() {
				let app_name = this.sreach_form.app_name;
				let data = this.$userData.changeSelectRange(app_name);

				this.sreach_form.app_channel = "";
				this.sreach_form.app_platform = "";
				this.sreach_form.app_channel_range = data.app_channel_range;
				this.sreach_form.app_platform_range = data.app_platform_range;
			},
		},
	}
</script>

<style lang="less">
	@import url('../common.less');

	.login {
		height: 100%;
		.flex_mid;

		.left,
		.right {
			width: 50%;
			height: 100%;
			position: relative;
		}

		.left {
			background: rgba(225, 228, 228, 0.5);

			img:nth-child(1) {
				position: absolute;
				width: 154px;
				height: 44px;
				top: 7px;
				left: 0px;
			}

			img:nth-child(2) {
				position: absolute;
				width: 696px;
				height: 806px;
				.ads_mid;
			}
		}

		.right {
			.content {
				.ads_mid;
				width: 330.24px;


				h3 {
					color: rgb(255, 161, 39);
					padding-bottom: 41px;
				}

				.el-input {
					height: 40px;
				}

				.el-input:nth-child(3) {
					margin-top: 20px;
				}

				.el-button {
					width: 100%;
					height: 40px;
					background: rgb(255, 165, 38);
					color: white;
					margin-top: 29px;
				}
			}
		}
	}

	.gameView {
		div {
			font-size: 12px;
			margin-bottom: 10px;

			.el-select {
				width: 100%;
			}
		}
	}
</style>