<template>
	<div class="home">
		<el-form :inline="true" ref="sreachForm" v-model="sreach_form" class="sreachForm">
			<el-form-item label="游戏:">
				<el-select v-model="sreach_form.app_name" placeholder="请选择游戏" @change="changeAppName">
					<el-option v-for="(item,index) in sreach_form.app_name_range" :key="index" :label="item.lbl"
						:value="item.lbl"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="平台:">
				<el-select v-model="sreach_form.app_platform" placeholder="请选择平台" @change="OnChangeSelect">
					<el-option v-for="(item,index) in sreach_form.app_platform_range" :key="index" :label="item.lbl"
						:value="item.lbl"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="渠道:">
				<el-select v-model="sreach_form.app_channel" placeholder="请选择渠道" @change="OnChangeSelect">
					<el-option v-for="(item,index) in sreach_form.app_channel_range" :key="index" :label="item.lbl"
						:value="item.lbl"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="区服:">
				<el-select v-model="sreach_form.server" placeholder="请选择区服" @visible-change="loadServer">
					<el-option v-for="(item,index) in sreach_form.server_range" :key="index" :label="item.lbl"
						:value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item>
				<div style="display: flex;align-items: center;">
					<el-date-picker v-model="sreach_form.start_date" type="date" placeholder="开始日期"
						value-format="yyyy-MM-dd" style="width: 150px;">
					</el-date-picker>

					<el-date-picker style="width: 150px;margin-left: 10px;" v-model="sreach_form.end_date" type="date"
						placeholder="结束日期" value-format="yyyy-MM-dd">
					</el-date-picker>
				</div>
			</el-form-item> -->
			<el-form-item>
				<el-button type="primary" @click="clickSreach">查询</el-button>
				<el-button @click="clickSreach">刷新</el-button>
			</el-form-item>
		</el-form>

		<div class="real_time">
			<h3>实时数据</h3>
			<loading v-if="loading1"></loading>
			<div id="real_time_chart" ref="real_time_chart"></div>
		</div>

		<div class="seven_day_data">
			<h3>近七天数据汇总</h3>
			<loading v-if="loading2"></loading>
			<div id="seven_day_data_chart" ref="seven_day_data_chart"></div>
		</div>
	</div>
</template>

<script>
	import sevenDayChart from '../js/echarts/sevenDayChart.js'
	import protocol from '../js/protocol.js'
	import loading from '../components/loading.vue'
	export default {
		name: 'home',
		data() {
			return {
				sreach_form: {
					app_name: "",
					app_channel: "",
					app_platform: "",
					server: "",
					server_range: [],
					start_date: "",
					end_date: "",
					app_name_range: [],
					app_channel_range: [],
					app_platform_range: [],
				},

				loading1: false,

				loading2: false
			}
		},

		components: {
			loading
		},

		mounted() {
			this.init();
		},

		methods: {

			async init() {
				let data = await this.$userData.getSelectRange();
				let curAppData = this.$userData.curAppData;
				if (!curAppData) {
					return;
				}

				this.sreach_form.app_name = curAppData.app_name;
				this.sreach_form.app_name_range = data.app_name_range;
				this.changeAppName();

				this.sreach_form.app_channel = curAppData.app_channel;
				this.sreach_form.app_platform = curAppData.app_platform;

				await this.realTimeChart();
				await this.sevenDayDataChart();
			},

			async loadServer(visible) {
				if (visible) {
					let res = {
						"app_id": this.$userData.curAppId
					};

					let ret = await this.$http.post(protocol.get_server_list, res);
					if (ret) {
						this.sreach_form.server_range = [];
						for (let data of ret) {
							this.sreach_form.server_range.push({
								id: data.server_id,
								lbl: data.server_name
							});
						}
					}
				}
			},

			clickSreach() {
				let app_name = this.sreach_form.app_name;
				let app_channel = this.sreach_form.app_channel;
				let app_platform = this.sreach_form.app_platform;

				for (let i = 0; i < this.$userData.app_list.length; i++) {
					let appData = this.$userData.app_list[i];
					if (app_name == appData.app_name && app_channel == appData.app_channel && app_platform == appData
						.app_platform) {
						this.$userData.saveCurAppData(appData);
						this.realTimeChart();
						this.sevenDayDataChart();
						return;
					}
				}

				this.$message({
					message: "未找到，对应游戏ID",
					type: 'error',
					duration: 1500
				});
			},

			changeAppName() {
				let app_name = this.sreach_form.app_name;
				let data = this.$userData.changeSelectRange(app_name);

				this.sreach_form.app_channel = "";
				this.sreach_form.app_platform = "";
				this.sreach_form.app_channel_range = data.app_channel_range;
				this.sreach_form.app_platform_range = data.app_platform_range;
			},

			async realTimeChart() {

				this.loading1 = true;

				let res = {
					"app_id": this.$userData.curAppId,
					"server_id": this.sreach_form.server
				};

				let ret = await this.$http.post(protocol.actual_time_data, res);
				let data = [0, 0, 0, 0, 0, 0, 0, 0];
				let titles = [];
				if (ret) {
					let i = 0;
					for (let key in ret) {
						titles[i] = key;
						data[i] = ~~ret[key];
						i++;
					}
				}

				if (!this.$refs.real_time_chart) {
					return;
				}

				var myChart = this.$echarts.init(this.$refs.real_time_chart);
				var option = {
					title: {},
					tooltip: {},
					legend: {},
					xAxis: {
						data: titles
					},
					yAxis: {},
					series: [{
						type: 'bar',
						data: data
					}]
				};

				myChart.setOption(option);

				this.loading1 = false;
			},

			async sevenDayDataChart() {
				this.loading2 = true;
				let option = await sevenDayChart.getSeverDayData(this.$userData.curAppId);
				if (!this.$refs.seven_day_data_chart) {
					return;
				}
				var myChart = this.$echarts.init(this.$refs.seven_day_data_chart);
				myChart.setOption(option);
				this.loading2 = false;
			},

			OnChangeSelect() {
				this.updateSelectAppId();
			},

			updateSelectAppId() {
				let app_name = this.sreach_form.app_name;
				let app_channel = this.sreach_form.app_channel;
				let app_platform = this.sreach_form.app_platform;

				for (let i = 0; i < this.$userData.app_list.length; i++) {
					let appData = this.$userData.app_list[i];

					if (app_name == appData.app_name && app_channel == appData.app_channel && app_platform == appData
						.app_platform) {
						this.clickSreach();
						this.loadServer(true);
						return;
					}
				}
			},
		}
	}
</script>

<style lang="less">
	.home {
		width: 85%;
		margin: auto;
		margin-top: 100px;

		.sreach_form {
			.el-select {
				width: 185px;
			}
		}

		.real_time {
			position: relative;
			margin-top: 20px;

			#real_time_chart {
				width: 100%;
				height: 400px;
			}
		}

		.seven_day_data {
			position: relative;
			margin-top: 20px;

			#seven_day_data_chart {
				height: 400px;
			}
		}
	}
</style>