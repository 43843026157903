import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '../page/login.vue'
import navView from '../page/navView.vue'
import home from '../page/home.vue'

import dataHome from '../page/data/dataHome.vue'
import dayData from '../page/data/date/dayData.vue'

import newUser from '../page/data/user/newUser.vue'
import userRetention from '../page/data/user/userRetention.vue'

import analysis from '../page/data/recharge/analysis.vue'
import ltvPage from '../page/data/recharge/ltvPage.vue'

import currencyLog from '../page/data/log/currencyLog.vue'
import behaviorLog from '../page/data/log/behaviorLog.vue'
import rechangerLog from '../page/data/log/rechangerLog.vue'


import operateHome from '../page/operate/operateHome.vue'

import email from '../page/operate/statistics/email.vue'

import userQuery from '../page/operate/tool/userQuery.vue'
import notice from '../page/operate/tool/notice.vue'

import rechargeLog from '../page/operate/order/rechargeLog.vue'


Vue.use(VueRouter);

const routes = [{
	path: '/login',
	name: '登录',
	component: login
}, {
	path: '/',
	name: '后台',
	component: navView,
	children: [{
		path: '/home',
		name: '主页',
		component: home
	}, {
		path: '/data',
		name: '数据',
		component: dataHome,
		children: [{
			path: '/data/date/dayData',
			name: '日报',
			component: dayData,
		}, {
			path: '/data/user/newUser',
			name: '新增用户',
			component: newUser,
		}, {
			path: '/data/user/userRetention',
			name: '用户留存',
			component: userRetention,
		}, {
			path: '/data/recharge/analysis',
			name: '付费分析',
			component: analysis,
		}, {
			path: '/data/log/currencyLog',
			name: '货币日志',
			component: currencyLog,
		}, {
			path: '/data/log/rechangerLog',
			name: '充值日志',
			component: rechangerLog,
		}, {
			path: '/data/log/behaviorLog',
			name: '行为日志',
			component: behaviorLog,
		}, {
			path: '/data/recharge/ltvPage',
			name: 'LTV',
			component: ltvPage,
		}]
	}, {
		path: '/operate',
		name: '运营',
		component: operateHome,
		children: [{
			path: '/operate/statistics/email',
			name: '邮件通知',
			component: email,
		}, {
			path: '/operate/tool/userQuery',
			name: '用户查询',
			component: userQuery,
		}, {
			path: '/operate/tool/notice',
			name: '公告功能 ',
			component: notice,
		}, {
			path: '/operate/order/rechargeLog',
			name: '充值日志 ',
			component: rechargeLog,
		}]
	}]
}];

const router = new VueRouter({
	mode: 'history',
	routes
});

router.beforeEach((to, from, next) => {
	document.title = to.name;
	next();
	// if (to.fullPath != '/login') {
	// 	let isLogin = localStorage.getItem('token') ? true : true;
	// 	if (!isLogin) {
	// 		next('/login');
	// 	} else {
	// 		next();
	// 	}
	// } else {
	// 	next();
	// }
});


export default router