<template>
	<div class="operateHome">

		<div class="data_menu">
			<el-menu @select="selectMenu" :default-active="activeIndex" class="el-menu-vertical-demo"
				background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" :router="true">

				<el-submenu index="/date">
					<template slot="title">
						<i class="el-icon-location"></i>
						<span>运营统计</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="" disabled>数据统计</el-menu-item>
						<el-menu-item index="/operate/statistics/email">邮件通知</el-menu-item>
						<el-menu-item index="" disabled>渠道统计</el-menu-item>
					</el-menu-item-group>
				</el-submenu>


				<el-submenu index="/user">
					<template slot="title">
						<i class="el-icon-location"></i>
						<span>运营工具</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="/operate/tool/userQuery">用户查询</el-menu-item>
						<el-menu-item index="/operate/tool/notice">公告功能</el-menu-item>
					</el-menu-item-group>
				</el-submenu>

				<el-submenu index="/recharge">
					<template slot="title">
						<i class="el-icon-location"></i>
						<span>订单</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="/operate/order/rechargeLog">充值日志</el-menu-item>
					</el-menu-item-group>
				</el-submenu>

			</el-menu>
		</div>

		<div class="content">

			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item v-for="(name,index) in breadcrumbList">{{name}}</el-breadcrumb-item>
			</el-breadcrumb>

			<div class="router">
				<router-view />
			</div>

		</div>

	</div>
</template>

<script>
	export default {
		name: 'operateHome',
		data() {
			return {
				activeIndex: "/operate/statistics/email",
				breadcrumbList: []
			}
		},

		watch: {
			$route(to, from) {
				this.updateBreadcrumb();
			}
		},

		mounted() {
			if ('/operate' == this.$router.currentRoute.fullPath) {
				this.$router.push({
					path: '/operate/statistics/email'
				});
			} else {
				this.activeIndex = this.$router.currentRoute.fullPath;
			}
			this.updateBreadcrumb();
		},

		methods: {
			updateBreadcrumb() {
				this.breadcrumbList = [];
				this.$route.matched.forEach(data => {
					this.breadcrumbList.push(data.name);
				});
			},

			selectMenu(data) {

			}
		}
	}
</script>

<style scoped lang="less">
	@import url('../../common.less');

	.operateHome {
		height: 100%;
		padding-bottom: 30px;

		.data_menu {
			width: 210px;
			height: 100%;
			background-color: #545c64;
			position: fixed;
			top: 60px;
		}

		.content {
			margin-top: 100px;
			margin-left: 210px + @left_offct;

			.router {
				margin-top: 30px;
			}
		}
	}

	/deep/ .el-menu {
		background-color: unset !important;
		border-right: none !important;
	}
</style>