<template>
	<div class="userQuery">

		<div class="sreach_form">

			<el-form :inline="true" ref="sreachForm" v-model="sreach_form">

				<el-form-item label="游戏:">
					<el-select v-model="sreach_form.game" placeholder="请选择游戏">
						<el-option v-for="(item,index) in sreach_form.range" :key="index" :label="item.name"
							:value="item.name"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="用户UID:">
					<el-select v-model="sreach_form.game" placeholder="请选择游戏">
						<el-option v-for="(item,index) in sreach_form.range" :key="index" :label="item.name"
							:value="item.name"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-date-picker v-model="sreach_form.date" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" align="right"
						value-format="yyyy-MM-dd"></el-date-picker>
				</el-form-item>

				<el-form-item>
					<el-button type="primary">查询</el-button>
					<el-button>导出</el-button>
				</el-form-item>

			</el-form>

		</div>

		<div class="table">
			<el-table :data="tableData" height="250" border style="width: 100%">
				<el-table-column prop="date" label="日期" width="180">
				</el-table-column>
				<el-table-column prop="name" label="姓名" width="180">
				</el-table-column>
				<el-table-column prop="address" label="地址">
				</el-table-column>
			</el-table>

			<el-pagination class="pagination" :current-page.sync="cur_page" :page-size="100"
				layout="prev, pager, next, jumper" :total="0">
			</el-pagination>
		</div>


	</div>
</template>

<script>
	export default {
		name: 'userQuery',
		data() {
			return {
				sreach_form: {
					game: "",
					range: [],
					date: ""
				},
				tableData: [],
				cur_page: 1
			}
		},

		mounted() {

		},

		methods: {

		}
	}
</script>

<style lang="less">
	.userQuery {
		width: calc(~'100% - 100px');

		.sreach_form {}

		.table {
			margin-top: 20px;

			.pagination {
				text-align: center;
				margin-top: 20px;
			}
		}
	}
</style>