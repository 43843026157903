import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import request from './js/request.js'
import EventManager from './js/event.js'
import userData from './js/userData.js'


Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;
Vue.prototype.$http = request.http;
Vue.prototype.$event = EventManager.instance;
Vue.prototype.$userData = userData;

Vue.use(ElementUI);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')