<template>
	<div class="newUser">

		<sreachForm @sreach="sreachNewUser"></sreachForm>

		<div class="chart">
			<el-button class="btn_export">导出</el-button>
			<el-radio-group v-model="select_tag" class="select_tag" @change="changeSelectNewData">
				<el-radio-button label="注册新增">注册新增</el-radio-button>
				<el-radio-button label="新增人均广告次数">新增人均广告次数</el-radio-button>
				<el-radio-button label="新增广告流水">新增广告流水</el-radio-button>
				<el-radio-button label="新增广告arpu">新增广告arpu</el-radio-button>
				<el-radio-button label="新增内购流水">新增内购流水</el-radio-button>
				<el-radio-button label="首日内购arpu">首日内购arpu</el-radio-button>
				<el-radio-button label="首日内购率">首日内购率</el-radio-button>
				<el-radio-button label="新增ltv">新增ltv</el-radio-button>
			</el-radio-group>
			<div style="position: relative;">
				<div id="userRetentionChart" ref="userRetentionChart"></div>
				<loading v-if="isLoad"></loading>
			</div>
		</div>

		<div class="table">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="日期" label="日期">
				</el-table-column>
				<el-table-column prop="注册新增" label="注册新增">
				</el-table-column>
				<el-table-column prop="新增人均广告次数" label="新增人均广告次数">
				</el-table-column>
				<el-table-column prop="新增广告流水" label="新增广告流水">
				</el-table-column>
				<el-table-column prop="新增广告arpu" label="新增广告arpu">
				</el-table-column>
				<el-table-column prop="新增内购流水" label="新增内购流水">
				</el-table-column>
				<el-table-column prop="首日内购arpu" label="首日内购arpu">
				</el-table-column>
				<el-table-column prop="首日内购率" label="首日内购率">
				</el-table-column>
				<el-table-column prop="新增ltv" label="新增ltv">
				</el-table-column>
			</el-table>

			<el-pagination class="pagination" :current-page.sync="page_index" :page-size="page_size"
				layout="prev, pager, next, jumper" :total="total_count">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue';
	import protocol from '../../../js/protocol.js'
	import loading from '../../../components/loading.vue';

	export default {

		name: 'newUser',

		data() {
			return {
				tableData: [],
				page_index: 1,
				page_size: 20,
				total_count: 0,
				start_time: "",
				end_time: "",
				select_tag: "注册新增",
				comboData: {
					categories: [],
					lineData: [],
					barData: []
				},
				isLoad: false
			}
		},

		components: {
			sreachForm,
			loading
		},

		mounted() {
			this.loadNewUserDataList();
		},

		methods: {

			changeSelectNewData() {
				this.loadNewUserDataList();
			},

			getDates() {
				const now = new Date();
				const sevenDaysBefore = new Date(now);
				sevenDaysBefore.setDate(now.getDate() - 7);

				let dates = [
					this.formatDate(sevenDaysBefore),
					this.formatDate(now)

				];

				return dates;
			},

			formatDate(date) {
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');

				return `${year}-${month}-${day}`;
			},

			async sreachNewUser(data) {
				this.loadNewUserDataList(data);
			},

			async loadNewUserDataList(data) {

				this.isLoad = true;

				let id = this.$userData.curAppId;

				let select_time = this.getDates();

				if (data) {
					id = data.id;

					if (data.date && data.date.length >= 2 && data.date[0] && data.date[1]) {
						select_time = data.date;
					}
				}

				let ret = await this.$http.post(protocol.new_user_data, {
					"app_id": id,
					"page_size": this.page_size,
					"select_time": select_time
				});

				this.tableData = [];

				this.comboData.categories = [];
				this.comboData.lineData = [],
					this.comboData.barData = [];

				if (ret) {

					for (let key in ret) {

						let data = {
							"日期": "",
							"注册新增": 0,
							"新增人均广告次数": 0,
							"新增广告流水": 0,
							"新增广告arpu": 0,
							"新增内购流水": 0,
							"首日内购arpu": 0,
							"首日内购率": 0,
							"新增ltv": 0,
						};

						this.comboData.categories.push(key);

						let v1 = ~~ret[key]['创角数'];
						let v2 = ~~ret[key]['激活数'];
						let v3 = ~~ret[key]['充值人数'];
						let v4 = ~~ret[key]['充值金额'];
						let v5 = ~~ret[key]['完播广告次数'];

						data['注册新增'] = v1;
						data['新增人均广告次数'] = v5 / v1;
						data['首日内购arpu'] = v4 / v1;
						data['首日内购率'] = v3 / v1;
						data['新增ltv'] = v4 / v1;

						for (let key1 in data) {
							data[key1] = !data[key1] || data[key1] == Infinity ? 0 : data[key1];
							data[key1] = Math.round(data[key1] * 100) / 100;
						}

						data['日期'] = key;

						this.tableData.push(data);

						this.comboData.lineData.push(data[this.select_tag]);
						this.comboData.barData.push(data[this.select_tag]);
					}

					this.showUserRetentionChart();
				}

				this.isLoad = false;
			},

			showUserRetentionChart() {

				if (!this.$refs.userRetentionChart) {
					return;
				}

				const comboChart = this.$echarts.init(this.$refs.userRetentionChart);

				const option = {
					title: {
						text: '用户留存'
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						data: this.comboData.categories
					},
					yAxis: [{
							type: 'value',
							name: '',
							min: 0,
							max: 400,
							interval: 100,
							axisLabel: {
								formatter: '{value}'
							}
						},
						{
							type: 'value',
							name: '',
							min: 0,
							max: 400,
							interval: 100,
							axisLabel: {
								formatter: '{value}'
							}
						}
					],
					series: [{
							name: this.select_tag,
							type: 'bar',
							data: this.comboData.barData,
							label: {
								show: true,
								position: 'top'
							}
						},
						{
							name: this.select_tag,
							type: 'line',
							yAxisIndex: 1,
							data: this.comboData.lineData
						}
					]
				};

				comboChart.setOption(option);
			}
		}
	}
</script>

<style lang="less">
	.newUser {
		padding-bottom: 30px;

		.chart {
			position: relative;

			.btn_export {
				position: absolute;
				right: 100px;
				top: 0px;
				background-color: rgba(0, 191, 191, 1);
				color: white;
				z-index: 10;
			}

			.select_tag {
				margin: 0;
				margin-bottom: 30px;
			}

			#userRetentionChart {
				height: 400px;
			}

			#newUserChart {
				height: 400px;
			}
		}

		.distribution {
			margin-top: 20px;

			position: relative;

			.select_tag {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				z-index: 10;
			}

			#distributionChart {
				height: 400px;
			}
		}
	}
</style>