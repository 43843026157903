<template>
	<div class="dataHome">

		<sreachForm @sreach="selectChartType"></sreachForm>

		<div class="chart">
			<h4>总览趋势</h4>
			<el-radio-group v-model="select_tag" class="select_tag" @change="selectChartType">
				<el-radio-button label="激活数">激活数</el-radio-button>
				<el-radio-button label="创角数">创角数</el-radio-button>
				<el-radio-button label="注册转化率">注册转化率</el-radio-button>
				<el-radio-button label="广告数量">广告数量</el-radio-button>
				<el-radio-button label="广告完播率">广告完播率</el-radio-button>
				<el-radio-button label="充值金额">充值金额</el-radio-button>
				<el-radio-button label="充值人数">充值人数</el-radio-button>
				<el-radio-button label="ARPU">ARPU</el-radio-button>
				<el-radio-button label="ARPPU">ARPPU</el-radio-button>
				<el-radio-button label="付费率">付费率</el-radio-button>
				<el-radio-button label="三日留存用户数">3日留存用户数</el-radio-button>
				<el-radio-button label="三日留存率">3日留存率</el-radio-button>
				<el-radio-button label="七日留存用户数">7日留存用户数</el-radio-button>
				<el-radio-button label="7日留存率">7日留存率</el-radio-button>
			</el-radio-group>

			<div style="position: relative;">
				<div id="day_chart" ref="day_chart">
				</div>
				<loading v-if="isLoad"></loading>
			</div>

		</div>

		<div class="table">
			<el-table :data="tableData" height="250" border style="width: 100%">
				<el-table-column prop="日期" label="日期">
				</el-table-column>
				<el-table-column prop="激活数" label="激活数">
				</el-table-column>
				<el-table-column prop="创角数" label="创角数">
				</el-table-column>
				<el-table-column prop="注册转化率" label="注册转化率">
				</el-table-column>
				<el-table-column prop="广告数量" label="广告数量">
				</el-table-column>
				<el-table-column prop="广告完播率" label="广告完播率">
				</el-table-column>
				<el-table-column prop="充值金额" label="充值金额">
				</el-table-column>
				<el-table-column prop="充值人数" label="充值人数">
				</el-table-column>
				<el-table-column prop="ARPU" label="ARPU">
				</el-table-column>
				<el-table-column prop="ARPPU" label="ARPPU">
				</el-table-column>
				<el-table-column prop="付费率" label="付费率">
				</el-table-column>
				<el-table-column prop="三日留存用户数" label="3日留存用户数">
				</el-table-column>
				<el-table-column prop="三日留存率" label="3日留存率">
				</el-table-column>
				<el-table-column prop="七日留存用户数" label="7日留存用户数">
				</el-table-column>
				<el-table-column prop="7日留存率" label="7日留存率">
				</el-table-column>
			</el-table>

			<el-pagination class="pagination" :current-page.sync="cur_page" :page-size="10"
				layout="prev, pager, next, jumper" :total="0">
			</el-pagination>
		</div>


	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue';
	import protocol from '../../../js/protocol.js'
	import loading from '../../../components/loading.vue';
	export default {
		name: 'dataHome',
		data() {
			return {
				select_tag: "激活数",
				chartData: {
					categories: [],
					data: []
				},
				tableData: [],
				cur_page: 1,
				lineChart: null,
				select_time: "",
				isLoad: false,
			}
		},
		components: {
			sreachForm,
			loading
		},
		mounted() {
			this.selectChartType();
		},

		methods: {

			async selectChartType(data) {

				this.isLoad = true;

				if (data) {
					this.select_time = data.date;
				}
				let ret = await this.$http.post(protocol.count_info, {
					"app_id": this.$userData.curAppId,
					"select_time": this.select_time == "" ? [] : this.select_time
				});
				if (ret) {
					this.chartData.categories = [];
					this.chartData.data = [];
					this.tableData = [];

					for (let key in ret) {

						let curData = ret[key];

						curData["注册转化率"] = (~~curData["创角数"] / ~~curData["激活数"]) * 100;
						curData["ARPU"] = (~~curData["充值金额"] / ~~curData["创角数"]);
						curData["ARPPU"] = (~~curData["充值金额"] / ~~curData["充值人数"]);
						curData["付费率"] = (~~curData["充值人数"] / ~~curData["创角数"]);

						for (let curKey in curData) {
							if (!curData[curKey]) {
								curData[curKey] = 0;
							}
						}

						this.tableData.push(curData);

						let value = "" + curData[this.select_tag];
						value = value.replace("%", "");
						value = Number(value);

						this.chartData.categories.push(key);
						this.chartData.data.push(value);
					}
				}
				this.showDayChart();

				this.isLoad = false;
			},

			showDayChart() {

				if (!this.$refs.day_chart) {
					return;
				}

				if (!this.lineChart) {
					this.lineChart = this.$echarts.init(this.$refs.day_chart);
				}

				const option = {
					title: {},
					tooltip: {},
					xAxis: {
						data: this.chartData.categories
					},
					yAxis: {},
					series: [{
						name: this.select_tag,
						type: 'line',
						data: this.chartData.data,
						label: {
							show: true,
							position: 'top'
						}
					}]
				};

				this.lineChart.setOption(option);
			}
		}
	}
</script>

<style lang="less">
	@import url('../../../common.less');

	.dataHome {

		.chart {

			.select_tag {
				margin-top: 30px;
			}

			#day_chart {
				height: 400px;
			}

			.day_chart {
				position: relative;
			}
		}

		.table {
			width: 90%;

			.pagination {
				text-align: center;
				margin-top: 30px;
			}
		}
	}
</style>