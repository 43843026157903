<template>
	<div class="analysis">
		<sreachForm @sreach="updateGameInfo"></sreachForm>
		<div class="info">

			<el-card class="info_item" shadow="never">
				<div>总付费额</div>
				<div class="count">{{getStatisticsData("总付费额")}}</div>
				<div class="hit">
					<el-popover placement="top-start" title="说明" width="200" trigger="hover" content="总付费额" disabled>
						<i class="el-icon-warning-outline" slot="reference"></i>
					</el-popover>
				</div>
				<div class="rate"> </div>
				<div class="dec">日均付费额 ¥ {{getStatisticsData("日均付费额")}}</div>
			</el-card>

			<el-card class="info_item" shadow="never">
				<div>支付笔数</div>
				<div class="count">{{getStatisticsData("支付笔数")}}</div>
				<div class="hit">
					<el-popover placement="top-start" title="说明" width="200" trigger="hover" content="支付笔数" disabled>
						<i class="el-icon-warning-outline" slot="reference"></i>
					</el-popover>
				</div>
				<div class="rate">
					<img src="../../../assets/u454.png" />
				</div>
				<div class="dec">付费人数 {{getStatisticsData("付费人数")}}</div>
			</el-card>

			<el-card class="info_item" shadow="never">
				<div>完播广告次数</div>
				<div class="count"> {{getStatisticsData("完播广告次数")}}</div>
				<div class="hit">
					<el-popover placement="top-start" title="说明" width="200" trigger="hover" content="完播广告次数" disabled>
						<i class="el-icon-warning-outline" slot="reference"></i>
					</el-popover>
				</div>
				<div class="rate">
					<img src="../../../assets/u464.png" />
				</div>
				<div class="dec">人均广告 {{getStatisticsData("人均广告")}}次</div>
			</el-card>

			<el-card class="info_item" shadow="never">
				<div>广告完播率</div>
				<div class="count">{{getStatisticsData("广告完播率")}}%</div>
				<div class="hit">
					<el-popover placement="top-start" title="说明" width="200" trigger="hover" content="广告完播率" disabled>
						<i class="el-icon-warning-outline" slot="reference"></i>
					</el-popover>
				</div>
				<div class="rate">
					<img src="../../../assets/u474.png" />
				</div>
				<!-- 	<div class="rate" style="border: unset;">
					周同比<i class="el-icon-caret-top" /> 0％ 日环比<i class="el-icon-caret-bottom" />0％
				</div> -->
			</el-card>


		</div>

		<div class="statistics">

			<el-tabs v-model="activeRechangerName" @tab-click="handleRechangerTabClick">
				<el-tab-pane label="总付费量" name="amount_sum"></el-tab-pane>
				<el-tab-pane label="用户首日付费量" name="recharge_first"></el-tab-pane>
				<el-tab-pane label="活跃付费用户" name="recharge_num"></el-tab-pane>
				<el-tab-pane label="活跃arppu" name="recharge_arppu"></el-tab-pane>
			</el-tabs>

			<div class="statistics_date">

				<el-date-picker @change="handleRechangerDateChange" v-model="rechangerDate[0]" type="date"
					placeholder="开始日期" value-format="yyyy-MM-dd" style="width: 150px;">
				</el-date-picker>

				<el-date-picker @change="handleRechangerDateChange" style="width: 150px;margin-left: 10px;"
					v-model="rechangerDate[1]" type="date" placeholder="结束日期" value-format="yyyy-MM-dd">
				</el-date-picker>

			</div>

			<div class="chart">
				<div id="rechargeChart" ref="rechargeChart"></div>
				<loading v-if="isRechangrLoading"></loading>
			</div>

		</div>

		<div class="statistics">

			<el-tabs v-model="activeAdName" @tab-click="handleAdTabClick">
				<el-tab-pane label="广告播放量" name="complete_ad"></el-tab-pane>
				<el-tab-pane label="人均广告播放量" name="complete_rate"></el-tab-pane>
			</el-tabs>

			<div class="statistics_date">
				<!-- <ul>
					<li>今日</li>
					<li>本周</li>
					<li>本月</li>
					<li>全年</li>
				</ul> -->
				<el-date-picker @change="handleAdDateChange" v-model="adDate[0]" type="date" placeholder="开始日期"
					value-format="yyyy-MM-dd" style="width: 150px;">
				</el-date-picker>

				<el-date-picker @change="handleAdDateChange" style="width: 150px;margin-left: 10px;" v-model="adDate[1]"
					type="date" placeholder="结束日期" value-format="yyyy-MM-dd">
				</el-date-picker>
			</div>

			<div class="chart">
				<div id="adChart" ref="adChart"></div>
				<loading v-if="isLoading"></loading>
			</div>

		</div>

		<div class="gear" v-show="pay_report_data">
			<h3 class="title">付费档位</h3>
			<div class="chart">
				<div class="gearChart" id="gearChart1"></div>
				<div class="gearChart" id="gearChart2"></div>
				<div class="gearChart" id="gearChart3"></div>
			</div>
		</div>

	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue';
	import loading from '../../../components/loading.vue';
	import protocol from '../../../js/protocol.js'
	import pancakeChart from '../../../js/echarts/pancakeChart.js'
	export default {
		name: 'analysis',
		data() {
			return {

				statisticsData: {
					"总付费额": 0,
					"日均付费额": 0,
					"支付笔数": 0,
					"付费人数": 0,
					"完播广告次数": 0,
					"广告完播率": 0,
					"广告总收入": 0,
					"人均广告": 0
				},

				activeAdLbl: "广告播放量",
				activeAdName: "complete_ad",
				adDate: ["", ""],

				activeRechangerLbl: "总付费量",
				activeRechangerName: "amount_sum",
				rechangerDate: ["", ""],

				pay_report_data: null,

				isRechangrLoading: false,
				isLoading: false
			}
		},
		components: {
			sreachForm,
			loading
		},
		mounted() {

			this.updateGameInfo();
		},

		methods: {

			async updateGameInfo(data) {
				this.updateRechangerInfo();
				this.updateAdInfo();
				this.showGearChart(data);


				let ret = await this.$http.post(protocol.advert_report_count, {
					"app_id": this.$userData.curAppId,
					"select_time": data && data.date[0] && data.date[1] ? data.date : ""
				});

				if (ret) {
					let amount_sum = ret.amount_sum;
					let complete_ad = ret.complete_ad;
					let complete_rate = ret.complete_rate;
					let recharge_total = ret.recharge_total;
					let days_num = ret.days_num;
					let person_num = ret.person_num;
					let ad_person_num = ~~ret.ad_person_num;

					this.statisticsData['总付费额'] = amount_sum;
					this.statisticsData['支付笔数'] = recharge_total;
					this.statisticsData['完播广告次数'] = complete_ad;
					this.statisticsData['广告完播率'] = complete_rate;
					this.statisticsData['付费人数'] = person_num;
					this.statisticsData['人均广告'] = complete_ad == 0 || ad_person_num == 0 ? 0 : Math.round((
						complete_ad / ad_person_num) * 100) / 100;
					this.statisticsData['日均付费额'] = Math.round((amount_sum / days_num) * 100) / 100;
				}
			},

			getStatisticsData(key) {
				return this.statisticsData[key];
			},

			handleRechangerTabClick(tab, event) {
				this.activeRechangerLbl = tab.label;
				this.updateRechangerInfo();
			},

			handleAdTabClick(tab, event) {
				this.activeAdLbl = tab.label;
				this.updateAdInfo();
			},

			handleRechangerDateChange() {
				if (!this.rechangerDate[0] || !this.rechangerDate[1]) {
					return;
				}
				if (!this.compareDates(this.rechangerDate[0], this.rechangerDate[1])) {
					this.$message({
						message: "开始期日不得高于结束日期",
						type: 'error',
						duration: 1500
					});
					return;
				}
				this.updateRechangerInfo();
			},

			handleAdDateChange() {
				if (!this.adDate[0] || !this.adDate[1]) {
					return;
				}
				if (!this.compareDates(this.adDate[0], this.adDate[1])) {
					this.$message({
						message: "开始期日不得高于结束日期",
						type: 'error',
						duration: 1500
					});
					return;
				}
				this.updateAdInfo();
			},

			updateRechangerInfo() {
				this.showRechargeChart();

			},

			updateAdInfo() {
				this.showAdChart();
			},

			async showGearChart(data) {

				let ret = await this.$http.post(protocol.pay_report_data, {
					"app_id": this.$userData.curAppId,
					"select_time": data && data.date[0] && data.date[1] ? data.date : ""
				});

				if (ret) {
					this.pay_report_data = ret;

					if (Array.isArray(this.pay_report_data) && this.pay_report_data.length <= 0) {
						this.pay_report_data = null;
					}
				}

				if (!this.pay_report_data) {
					return;
				}

				let titles = ['各档位付费金额比例（单位：rmb）', '各档位付费次数比例（单位：rmb）', '累计充值比例（单位：rmb）'];

				let rechangerData = {
					"1": {
						value: 0,
						list: []
					},
					"2": {
						value: 0,
						list: []
					},
					"3": {
						value: 0,
						list: []
					},
				};

				let amount_sum = 0;

				let pay_times = 0;

				let zhanbi_amount_sum = 0;

				let zhanbi_pay_times = 0;

				for (let key in this.pay_report_data) {
					if (key != "recharge_count" && key != "recharge_count_key") {

						amount_sum += ~~this.pay_report_data[key].amount_sum;
						pay_times += ~~this.pay_report_data[key].pay_times;

						rechangerData["1"].list.push({
							value: this.pay_report_data[key].amount_sum,
							name: key,
							customField: this.pay_report_data[key].amount_sum
						});

						rechangerData["2"].list.push({
							value: this.pay_report_data[key].pay_times,
							name: key,
							customField: this.pay_report_data[key].pay_times
						});

					} else if ("recharge_count_key" == key) {
						let list = this.pay_report_data[key];
						let recharge_count = this.pay_report_data['recharge_count'];

						for (let i = 0; i < list.length; i++) {

							let info = list[i];

							let curRechargeData = recharge_count[i];

							zhanbi_amount_sum += ~~curRechargeData.amount_sum;
							zhanbi_pay_times += ~~curRechargeData.pay_times;

							rechangerData["3"].list.push({
								value: ~~curRechargeData.amount_sum,
								name: `[${info.min},${info.max}]`,
								customField: ~~curRechargeData.amount_sum
							});
						}
					}
				}

				for (let key in rechangerData) {

					let sum = 0;
					if (key == "1") {
						sum = amount_sum;
						rechangerData["1"].value = amount_sum;
					}
					if (key == "2") {
						sum = pay_times;
						rechangerData["2"].value = pay_times;
					}
					if (key == "3") {
						sum = zhanbi_amount_sum;
						rechangerData["3"].value = zhanbi_amount_sum;
					}

					for (let i = 0; i < rechangerData[key].list.length; i++) {
						let value = rechangerData[key].list[i].value;
						if (sum > 0 && value > 0) {
							rechangerData[key].list[i].value = value / sum;
						} else {
							rechangerData[key].list[i].value = 0;
						}
					}
				}

				for (let i = 1; i <= titles.length; i++) {

					let dom = document.getElementById('gearChart' + i);
					if (!dom) {
						continue;
					}

					const pieChart = this.$echarts.init(dom);
					const option = pancakeChart.getChart(i - 1, rechangerData[i]);
					pieChart.setOption(option);
				}
			},

			async showAdChart() {

				this.isLoading = true;

				let ret = await this.$http.post(protocol.report_everyday, {
					"app_id": this.$userData.curAppId,
					"select_time": this.adDate[0] && this.adDate[1] ? this.adDate : ""
				});

				if (!this.$refs.adChart) {
					return;
				}

				const barChart = this.$echarts.init(this.$refs.adChart);
				const dates = [];
				const amounts = [];

				if (ret) {
					for (let key in ret) {
						dates.push(key);
						amounts.push(~~ret[key][this.activeAdName]);
					}
				}

				const option = {
					title: {
						text: '广告趋势',
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						data: dates
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: `{value}${this.activeAdName=='complete_rate'?"%":""}`
						}
					},
					series: [{
						name: this.activeAdLbl,
						data: amounts,
						barMaxWidth: 30,
						barMinWidth: 30,
						type: 'bar',
						label: {
							show: true,
							position: 'top',
							formatter: (params) => {
								let char = this.activeAdName == 'complete_rate' ? "%" : "";
								return params.value + char;
							}
						}
					}]
				};

				barChart.setOption(option);

				this.isLoading = false;
			},

			async showRechargeChart() {
				this.isRechangrLoading = true;
				let ret = await this.$http.post(protocol.report_everyday_pay, {
					"app_id": this.$userData.curAppId,
					"select_time": this.rechangerDate[0] && this.rechangerDate[1] ? this.rechangerDate : ""
				});

				if (!this.$refs.rechargeChart) {
					return;
				}

				const barChart = this.$echarts.init(this.$refs.rechargeChart);
				const dates = [];
				const amounts = [];

				if (ret) {
					for (let key in ret) {
						dates.push(key);
						if (this.activeRechangerName == "recharge_arppu") {
							let amount_sum = ~~ret[key]["amount_sum"];
							let recharge_num = ~~ret[key]["recharge_num"];
							let value = amount_sum == 0 || recharge_num == 0 ? 0 : amount_sum / recharge_num;
							amounts.push(value);
						} else {
							amounts.push(~~ret[key][this.activeRechangerName]);
						}
					}
				}

				const option = {
					title: {
						text: '付费趋势',

					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						data: dates
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value}'
						}
					},
					series: [{
						name: this.activeRechangerLbl,
						data: amounts,
						barMaxWidth: 30,
						barMinWidth: 30,
						type: 'bar',
						label: {
							show: true,
							position: 'top'
						}
					}]
				};
				barChart.setOption(option);
				this.isRechangrLoading = false;
			},

			compareDates(start_date, end_date) {
				if (!start_date || !end_date) {

					return true;
				}
				const d1 = new Date(start_date);
				const d2 = new Date(end_date);
				if (d1 > d2) {

					return false;
				}
				return true;
			}
		}
	}
</script>

<style lang="less">
	.analysis {
		padding-bottom: 30px;

		.info {
			display: flex;
			align-items: center;

			.info_item {
				width: 266px;
				height: 188px;
				position: relative;
				margin-right: 30px;
				padding-bottom: 10px;

				.count {

					font-weight: bold;
					font-size: 30px;
					margin-top: 10px;
				}

				.rate {
					color: rgba(0, 0, 0, 0.6);
					margin-top: 10px;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
					padding-bottom: 20px;
					height: 25px;

					i {
						padding-left: 5px;
						padding-right: 5px;
					}

					i:nth-child(1) {
						color: rgba(82, 196, 26, 0.6);
					}

					i:nth-child(2) {
						color: #F5222D;
					}

					img {
						width: 218px;

					}
				}

				.hit {
					position: absolute;
					top: 10px;
					right: 10px;
				}

				.dec {
					color: rgba(0, 0, 0, 0.6);
					margin-top: 10px;
				}
			}
		}

		.statistics {
			margin-top: 40px;
			width: calc(~"100% - 100px");
			position: relative;

			.statistics_date {
				position: absolute;
				right: 0;
				top: -10px;
				display: flex;
				align-items: center;
			}

			ul {
				margin-right: 10px;
				display: flex;
				align-items: center;

				li {
					cursor: pointer;
					font-size: 13px;
					margin-left: 20px;
					color: rgba(0, 0, 0, 0.6);
				}
			}

			.chart {
				width: 1400px;

				#rechargeChart,
				#adChart {
					height: 400px;
				}
			}
		}

		.gear {
			width: calc(~"100% - 100px");

			.title {
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				padding-bottom: 10px;
			}

			.gearChart {
				width: 700px;
				height: 400px;
				display: inline-block;
				margin-top: 30px;
			}
		}
	}
</style>