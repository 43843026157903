<template>
	<div class="sreachForm">
		<el-form :inline="true" ref="sreachForm" v-model="sreach_form">
			<el-form-item label="游戏:">
				<el-select v-model="sreach_form.app_name" placeholder="请选择游戏" @change="changeAppName">
					<el-option v-for="(item,index) in sreach_form.app_name_range" :key="index" :label="item.lbl"
						:value="item.lbl"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="平台:">
				<el-select v-model="sreach_form.app_platform" placeholder="请选择平台" @change="OnChangeSelect">
					<el-option v-for="(item,index) in sreach_form.app_platform_range" :key="index" :label="item.lbl"
						:value="item.lbl"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="渠道:">
				<el-select v-model="sreach_form.app_channel" placeholder="请选择渠道" @change="OnChangeSelect">
					<el-option v-for="(item,index) in sreach_form.app_channel_range" :key="index" :label="item.lbl"
						:value="item.lbl"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="区服:">
				<el-select v-model="sreach_form.server" placeholder="请选择区服" @visible-change="blurServer()">
					<el-option v-for="(item,index) in sreach_form.server_range" :key="index" :label="item.lbl"
						:value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-if="isShowDay" label="日期:">
				<div style="display: flex;align-items: center;">
					<el-date-picker v-model="sreach_form.start_date" type="date" placeholder="开始日期"
						value-format="yyyy-MM-dd" style="width: 150px;">
					</el-date-picker>

					<el-date-picker style="width: 150px;margin-left: 10px;" v-model="sreach_form.end_date" type="date"
						placeholder="结束日期" value-format="yyyy-MM-dd">
					</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item v-if="isShowCostType" label="进销类型:">
				<el-select v-model="type1" placeholder="请选择进销类型">
					<el-option v-for="(item,index) in type1_range" :key="index" :label="item.lbl"
						:value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-if="isShowCostType" label="进销途径:">
				<el-select v-model="type2" placeholder="请选择进销途径">
					<el-option v-for="(item,index) in type2_range" :key="index" :label="item.lbl"
						:value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="clickSreach">查询</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import protocol from "../js/protocol.js"

	export default {
		name: 'sreachForm',
		data() {
			return {
				sreach_form: {
					app_name: "",
					app_channel: "",
					app_platform: "",
					server: "",
					server_range: [],
					start_date: "",
					end_date: "",
					app_name_range: [],
					app_channel_range: [],
					app_platform_range: [],
				},

				type1_range: [],
				type2_range: [],
				type1: "",
				type2: "",
			}
		},

		props: {
			isShowDay: {
				type: Boolean,
				default: true
			},
			isShowCostType: {
				type: Boolean,
				default: false
			}
		},

		mounted() {
			this.init();
		},

		methods: {

			async init() {
				let data = await this.$userData.getSelectRange();
				let curAppData = this.$userData.curAppData;
				if (!curAppData) {
					return;
				}

				this.sreach_form.app_name = curAppData.app_name;
				this.sreach_form.app_channel = curAppData.app_channel;
				this.sreach_form.app_platform = curAppData.app_platform;

				this.sreach_form.app_name_range = data.app_name_range;
				this.sreach_form.app_channel_range = data.app_channel_range;
				this.sreach_form.app_platform_range = data.app_platform_range;

				if (this.isShowCostType) {
					await this.initTypeRange();
				}
			},

			async blurServer(id) {

				let curId = id ? id : this.$userData.curAppId;
				let res = {
					"app_id": curId
				};
				let ret = await this.$http.post(protocol.get_server_list, res);
				if (ret) {
					this.sreach_form.server_range = [];
					for (let data of ret) {
						this.sreach_form.server_range.push({
							id: data.server_id,
							lbl: data.server_name
						});
					}
				}
			},

			async initTypeRange() {

				let list1 = await this.$userData.GetCategoryList("money");

				this.type1_range = [{
					id: 0,
					lbl: "全部"
				}];

				this.type2_range = [{
					id: 0,
					lbl: "全部"
				}];

				this.type1 = 0;
				this.type2 = 0;

				if (list1) {
					for (let i = 0; i < list1[0].length; i++) {
						let data = list1[0][i];
						this.type1_range.push({
							id: data["cid"],
							lbl: data["name"]
						});
					}
				}

				if (list1) {
					for (let i = 0; i < list1[1].length; i++) {
						let data = list1[1][i];
						this.type2_range.push({
							id: data["cid"],
							lbl: data["name"]
						});
					}
				}
			},

			changeAppName() {
				let app_name = this.sreach_form.app_name;
				let data = this.$userData.changeSelectRange(app_name);

				this.sreach_form.app_channel = "";
				this.sreach_form.app_platform = "";
				this.sreach_form.server = "";
				this.sreach_form.server_range = [];
				this.sreach_form.app_channel_range = data.app_channel_range;
				this.sreach_form.app_platform_range = data.app_platform_range;
			},

			OnChangeSelect() {
				this.updateSelectAppId();
			},

			updateSelectAppId() {
				let app_name = this.sreach_form.app_name;
				let app_channel = this.sreach_form.app_channel;
				let app_platform = this.sreach_form.app_platform;

				for (let i = 0; i < this.$userData.app_list.length; i++) {
					let appData = this.$userData.app_list[i];
					if (app_name == appData.app_name && app_channel == appData.app_channel && app_platform == appData
						.app_platform) {
						this.clickSreach();
						this.blurServer(appData.id);
						return;
					}
				}
			},

			clickSreach() {

				if (!this.compareDates()) {
					this.$message({
						message: "开始期日不得高于结束日期",
						type: 'error',
						duration: 1500
					});
					return;
				}

				let app_name = this.sreach_form.app_name;
				let app_channel = this.sreach_form.app_channel;
				let app_platform = this.sreach_form.app_platform;

				for (let i = 0; i < this.$userData.app_list.length; i++) {
					let appData = this.$userData.app_list[i];
					if (app_name == appData.app_name && app_channel == appData.app_channel && app_platform == appData
						.app_platform) {
						this.$userData.saveCurAppData(appData);


						let seachData = {
							id: appData.id,
							server_id: this.sreach_form.server,
							date: [this.sreach_form.start_date, this.sreach_form.end_date]
						};

						if (this.isShowCostType) {
							seachData["cid2"] = this.type1;
							seachData["cid3"] = this.type2;
						}

						this.$emit('sreach', seachData);
						return;
					}
				}

				this.$message({
					message: "未找到，对于游戏ID",
					type: 'error',
					duration: 1500
				});
			},

			compareDates() {

				if (!this.sreach_form.start_date || !this.sreach_form.end_date) {

					return true;
				}

				const d1 = new Date(this.sreach_form.start_date);
				const d2 = new Date(this.sreach_form.end_date);

				if (d1 > d2) {
					return false;
				}

				return true;
			}
		}
	}
</script>

<style lang="less">
	.sreachForm {

		width: calc(~"100% - 100px");

		.el-select {
			width: 185px;
		}

		border-bottom: 1px solid rgba(0, 0, 0, .1);
		margin-bottom: 20px;
	}
</style>