<template>
	<div class="notice">
		<sreachForm></sreachForm>

		<div>
			<el-radio-group v-model="select_tag" class="select_tag">
				<el-radio-button label="1">消息通知</el-radio-button>
				<el-radio-button label="2">发布消息</el-radio-button>
			</el-radio-group>
		</div>

		<div class="table" v-if="select_tag =='1'">
			<div class="title">公告列表</div>
			<el-table :data="tableData" height="250" border style="width: 100%">
				<el-table-column prop="date" label="日期" width="180">
				</el-table-column>
				<el-table-column prop="name" label="姓名" width="180">
				</el-table-column>
				<el-table-column prop="address" label="地址">
				</el-table-column>
			</el-table>

			<el-pagination class="pagination" :current-page.sync="cur_page" :page-size="100"
				layout="prev, pager, next, jumper" :total="0">
			</el-pagination>
		</div>

		<div class="send_content" v-if="select_tag =='2'">
			<div class="title">发布公告</div>
			<div class="send_content_form">

				<el-form v-model="send_content" :inline="true" ref="send_content">

					<el-form-item label="游戏：">
						<el-select v-model="send_content.game" placeholder="请选择游戏">
							<el-option v-for="(item,index) in send_content.range" :key="index" :label="item.name"
								:value="item.name"></el-option>
						</el-select>
					</el-form-item>



					<el-form-item label="平台：">
						<el-select v-model="send_content.game" placeholder="请选择平台">
							<el-option v-for="(item,index) in send_content.range" :key="index" :label="item.name"
								:value="item.name"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="渠道：">
						<el-select v-model="send_content.game" placeholder="请选择渠道">
							<el-option v-for="(item,index) in send_content.range" :key="index" :label="item.name"
								:value="item.name"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="区服：">
						<el-select v-model="send_content.game" placeholder="请选择区服">
							<el-option v-for="(item,index) in send_content.range" :key="index" :label="item.name"
								:value="item.name"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item>
						<el-date-picker v-model="send_content.date" type="daterange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" align="right"
							value-format="yyyy-MM-dd"></el-date-picker>
					</el-form-item>

				</el-form>

				<div class="msg_content">
					<div>公告内容：</div>
					<el-input style="width: 800px;" v-model="send_content.game" placeholder="请输入内容" type="textarea"
						:rows="10"></el-input>
				</div>

				<div class="btn_list">
					<el-button type="primary">确定</el-button>
					<el-button>取消</el-button>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue'
	export default {
		name: 'notice',
		data() {
			return {
				select_tag: "1",
				tableData: [],
				cur_page: 1,
				send_content: {
					game: "",
					range: [],
					date: "",
					msg: ""
				}
			}
		},

		components: {
			sreachForm
		},

		mounted() {

		},

		methods: {

		}
	}
</script>

<style scoped lang="less">
	.notice {

		width: calc(~'100% - 100px');

		.title {
			padding-bottom: 10px;
			font-weight: bold;
		}

		.table {
			margin-top: 20px;

			.pagination {
				text-align: center;
				margin-top: 20px;
			}
		}

		.send_content {
			margin-top: 20px;

			.send_content_form {

				position: relative;

				.el-select,
				.el-input {
					width: 200px;
				}

				.msg_content {
					display: flex;
				}

				.btn_list {
					position: absolute;
					left: 80px;
					bottom: -50px;
				}
			}
		}
	}
</style>