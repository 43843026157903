import request from "../request";
import protocol from "../protocol";


async function getSeverDayData(app_id) {

	var data = [];

	let ret = await request.http.post(protocol.seven_day_data, {
		"app_id": app_id,
	});

	if (ret) {
		for (let key in ret) {
			let curData = ret[key];
			let liucun = "" + curData["次日留存"]["次日留存率"];
			let liucunnum = liucun.replace("%", "");
			data.push({
				date: key,
				totalActivations: ~~curData["充值人数"],
				totalNewPlayers: ~~curData["充值金额"],
				totalPayers: ~~curData["创角数"],
				totalRevenue: ~~curData["完播广告次数"],
				avgAds: ~~liucunnum,
				cumulativeAds: ~~curData["次日留存"]["次日留存用户数"],
				arpu: ~~curData["激活数"],
				retentionRate: 0
			});

			if (data.length == 7) {
				break;
			}
		}
	}

	var dates = data.map(item => item.date);
	var totalActivations = data.map(item => item.totalActivations);
	var totalNewPlayers = data.map(item => item.totalNewPlayers);
	var totalPayers = data.map(item => item.totalPayers);
	var totalRevenue = data.map(item => item.totalRevenue);
	var avgAds = data.map(item => item.avgAds);
	var cumulativeAds = data.map(item => item.cumulativeAds);
	var arpu = data.map(item => item.arpu);
	var retentionRate = data.map(item => item.retentionRate);

	var option = {

		tooltip: {
			trigger: 'axis'
		},
		legend: {
			data: ['充值人数', '充值金额', '创角数', '完播广告次数', '次日留存率', '次日留存用户数', '激活数']
		},
		xAxis: {
			type: 'category',
			data: dates
		},
		yAxis: {
			type: 'value'
		},
		series: [{
				name: '充值人数',
				type: 'bar',
				data: totalActivations
			},
			{
				name: '充值金额',
				type: 'bar',
				data: totalNewPlayers
			},
			{
				name: '创角数',
				type: 'bar',
				data: totalPayers
			},
			{
				name: '完播广告次数',
				type: 'bar',
				data: totalRevenue
			},
			{
				name: '次日留存率',
				type: 'bar',
				data: avgAds,
			},
			{
				name: '次日留存用户数',
				type: 'bar',
				data: cumulativeAds,

			},
			{
				name: '激活数',
				type: 'bar',
				data: arpu
			}
		]
	};

	return option;
};

export default {
	getSeverDayData
}