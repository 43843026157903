<template>
	<div class="currencyLog">

		<sreachForm @sreach="sreachCurrencyLog"></sreachForm>

		<div class="table">
			<el-table :data="tableData" height="550" border style="width: 100%">
				<el-table-column prop="order_no" label="订单号">
				</el-table-column>
				<el-table-column prop="player_name" label="角色名">
				</el-table-column>
				<el-table-column prop="gift_id" label="礼包ID">
				</el-table-column>
				<el-table-column prop="amount" label="金额">
				</el-table-column>
				<el-table-column prop="status_text" label="状态">
				</el-table-column>
				<el-table-column prop="version_no" label="版本号">
				</el-table-column>
				<el-table-column prop="create_time" label="创建时间">
					<template slot-scope="scope">
						{{ formatDate(scope.row.create_time) }}
					</template>
				</el-table-column>
				<el-table-column prop="pay_time" label="支付时间">
					<template slot-scope="scope">
						{{ formatDate(scope.row.pay_time) }}
					</template>
				</el-table-column>
			</el-table>

			<el-pagination @current-change="handleCurrentChange" class="pagination" :current-page.sync="page_index"
				:page-size="page_size" layout="prev, pager, next, jumper" :total="total_count">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue';
	import protocol from '../../../js/protocol.js'
	export default {
		name: 'currencyLog',
		data() {
			return {

				appId: 0,

				server_id: "",

				select_time: [],

				tableData: [],

				page_index: 1,

				page_size: 20,

				total_count: 0
			}
		},

		components: {
			sreachForm
		},

		mounted() {
			this.appId = this.$userData.curAppId;
			this.loadRechangerData();
		},

		methods: {
			async loadRechangerData() {
				let ret = await this.$http.post(protocol.recharge_list, {
					"app_id": this.appId,
					"page_index": this.page_index,
					"select_time": this.select_time,
					"server_id": this.server_id
				});

				if (ret) {
					this.tableData = ret.list;
					this.page_index = ret.page_index;
					this.page_size = ret.page_size;
					this.total_count = ~~ret.total_count;
				}
			},

			formatDate(timestamp) {
				const date = new Date(timestamp * 1000);
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');

				return `${year}-${month}-${day}`;
			},

			async sreachCurrencyLog(data) {

				this.select_time = data.date;

				this.server_id = data.server_id;

				this.appId = data.id;

				this.loadRechangerData();

			},

			handleCurrentChange(page) {
				this.page_index = page;
				this.loadRechangerData();
			}
		}
	}
</script>

<style lang="less">
	.currencyLog {
		.sreach_form {
			.el-select {
				width: 140px;
			}
		}
	}
</style>