<template>
	<div class="behaviorLog">

		<sreachForm @sreach="showContent"></sreachForm>

		<!--<div class="chart">
			 <div class="select_tag">
				<el-radio-group v-model="select_tag" class="select_tag">
					<el-radio-button label="1">付费用户</el-radio-button>
					<el-radio-button label="2">广告用户</el-radio-button>
					<el-radio-button label="3">有效用户</el-radio-button>
					<el-radio-button label="4">所有用户</el-radio-button>
				</el-radio-group>
			</div> 

			<div id="guideChart" ref="guideChart">
			</div>
			<loading v-if="isLoading"></loading>
		</div>-->

		<div class="bhvLog">

			<el-form :inline="true" ref="bhvForm" v-model="bhv_form">

				<el-form-item>
					<h3>常规行为日志</h3>
				</el-form-item>

				<el-form-item label="一级行为:">
					<el-select v-model="bhv_form.main_bhv" placeholder="请选择行为">
						<el-option v-for="(item,index) in bhv_form.main_range" :key="index" :label="item.name"
							:value="item.cid"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="次级行为:">
					<el-select v-model="bhv_form.sub_bhv" placeholder="请选择行为">
						<el-option v-for="(item,index) in bhv_form.sub_range" :key="index" :label="item.name"
							:value="item.cid"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="参数1:" class="params">
					<el-select v-model="bhv_form.params_type1">
						<el-option v-for="(item,index) in bhv_form.params_type_range" :key="index" :label="item.name"
							:value="item.name"></el-option>
					</el-select>
					<el-input v-model="bhv_form.params1"></el-input>
				</el-form-item>

				<el-form-item label="参数2:" class="params">
					<el-select v-model="bhv_form.params_type2">
						<el-option v-for="(item,index) in bhv_form.params_type_range" :key="index" :label="item.name"
							:value="item.name"></el-option>
					</el-select>
					<el-input v-model="bhv_form.params2"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="clickSreachBhv">查询</el-button>
					<el-button>导出</el-button>
				</el-form-item>

			</el-form>

		</div>

		<div class="table">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="date" label="日期">
				</el-table-column>
				<el-table-column prop="num" label="人均次数">
				</el-table-column>
				<el-table-column prop="people" label="人数">
				</el-table-column>
				<el-table-column prop="count" label="总次数">
				</el-table-column>
			</el-table>

			<!-- 			<el-pagination class="pagination" :current-page.sync="cur_page" :page-size="100"
				layout="prev, pager, next, jumper" :total="1000">
			</el-pagination> -->
		</div>

	</div>
</template>

<script>
	import protocol from '../../../js/protocol.js'
	import sreachForm from '../../../components/sreachForm.vue';
	import loading from '../../../components/loading.vue';
	export default {
		name: 'behaviorLog',
		data() {
			return {
				sreach_form: {
					game: "",
					date: "",
					range: []
				},
				bhv_form: {
					main_bhv: "",
					sub_bhv: "",
					main_range: [],
					sub_range: [],
					params_type1: "=",
					params_type2: "=",
					params1: "",
					params2: "",
					params_type_range: [{
						name: ">",
					}, {
						name: "=",
					}, {
						name: "<",
					}, {
						name: ">=",
					}, {
						name: "<=",
					}]
				},
				tableData: [],
				cur_page: 1,
				select_tag: "1",
				isLoading: false,
			}
		},
		components: {
			sreachForm,
			loading
		},
		mounted() {
			this.showContent();
		},

		methods: {

			showContent() {
				this.loadBhvList();
				// this.showGuideChart();
			},

			async loadBhvList() {
				let res = {
					"app_id": this.$userData.curAppId,
					"type": "custom"
				};
				let ret = await this.$http.post(protocol.get_category_list, res);
				if (ret) {
					this.bhv_form.main_range = ret[0];
					this.bhv_form.sub_range = ret[1];
				}
			},

			async clickSreachBhv() {
				let res = {
					"app_id": this.$userData.curAppId,
					"cid1": this.bhv_form.main_bhv,
					"cid2": this.bhv_form.sub_bhv,
					"cid2_val": this.bhv_form.params1,
					"cid3_val": this.bhv_form.params2,
					"cid2_calculate": this.bhv_form.params_type1,
					"cid3_calculate": this.bhv_form.params_type2,
				};
				let ret = await this.$http.post(protocol.get_custom_list, res);
				if (ret) {
					this.tableData = [];
					for (let data of ret) {
						this.tableData.push({
							date: data['日期'],
							num: data['人均次数'],
							people: data['人数'],
							count: data['总次数']
						});
					}

				}
			},

			async showGuideChart() {

				this.isLoading = true;

				const dates = [];
				const amounts = [];

				let res = {
					"app_id": this.$userData.curAppId,

				};
				let ret = await this.$http.post(protocol.get_new_task_report, res);

				if (!this.$refs.guideChart) {
					return;
				}

				const barChart = this.$echarts.init(this.$refs.guideChart);
				if (ret) {
					for (let data of ret) {
						dates.push(data.cid_3_val);
						amounts.push(~~data.total_num);
					}
				}

				const option = {
					title: {
						text: '新手指引人数',
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						data: dates
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value}'
						}
					},
					series: [{
						data: amounts,
						barWidth: "10%",
						type: 'bar'
					}]
				};

				barChart.setOption(option);

				this.isLoading = false;
			}
		}
	}
</script>

<style lang="less">
	.behaviorLog {

		padding-bottom: 30px;

		.chart {
			position: relative;

			margin-top: 50px;

			.select_tag {
				position: absolute;
				top: -30px;
				right: 70px;
				display: flex;
				align-items: center;
				z-index: 100;
			}

			#guideChart {
				height: 400px;
			}
		}

		.bhvLog {
			.el-select {
				width: 200px;
			}

			.params {
				.el-select:nth-child(1) {
					width: 65px;
					margin-right: 10px;
				}

				.el-input:nth-child(2) {
					width: 200px;
					margin-left: 5px;
				}
			}
		}
	}
</style>