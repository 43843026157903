import axios from 'axios';
import router from '@/router';

import {
	Message
} from 'element-ui';

class HttpHelper {

	apiClient = null;

	baseURL = "/api";

	constructor() {
		this.apiClient = axios.create({
			baseURL: this.baseURL,
			headers: {
				'Content-Type': 'application/json',
			}
		});

		this.apiClient.interceptors.request.use(
			config => {
				const token = localStorage.getItem('token');
				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				}
				return config;
			},
			error => {
				return Promise.reject(error);
			}
		);
	}

	setLoginToken(token) {
		localStorage.setItem('token', token);
	}

	checkIsLoginOver(code) {
		return code == 900004 || code == 900003 || code == 900001 || code == 900002;
	}


	async post(url, param = {}) {

		console.log("ret: " + url, param)

		const response = await this.apiClient.post(url, param);
		if (response.data) {
			let code = response.data.code;
			if (code == 200) {
				const style = 'background: green; color: white; font-size: 12px; padding: 5px;';
				console.log('%c' + url, style, response.data);

				return response.data.result;
			} else {
				const style = 'background: red; color: white; font-size: 12px; padding: 5px;';
				console.log('%c' + url, style, response.data);

				if (this.checkIsLoginOver(code)) {

					Message({
						message: "登录过期，重新登录",
						type: 'error',
						duration: 1500
					});

					if (router.currentRoute.fullPath != '/login') {
						router.push('/login');
					}

					return null;
				}

				Message({
					message: response.data.msg,
					type: 'error',
					duration: 1500
				});

				return null;
			}
		}
	}
}



const http = new HttpHelper();

export default {
	http
}