<template>
	<div class="currencyLog">

		<sreachForm :is-show-cost-type="true" @sreach="sreachCurrencyLog"></sreachForm>

		<div class="table">
			<el-table :data="tableData" height="550" border style="width: 100%">
				<el-table-column prop="app_id" label="游戏">
					<template slot-scope="scope">
						{{ getAppName(scope.row.app_id) }}
					</template>
				</el-table-column>
				<el-table-column prop="uid" label="角色ID">
				</el-table-column>
				<el-table-column prop="cid_2" label="类型">
					<template slot-scope="scope">
						{{ type_list[scope.row.cid_2] }}
					</template>
				</el-table-column>
				<el-table-column prop="cid_3" label="消耗途径">
					<template slot-scope="scope">
						{{ type_list_tow[scope.row.cid_3] }}
					</template>
				</el-table-column>
				<el-table-column prop="cid_3_val" label="数量">
				</el-table-column>
				<el-table-column prop="server_id" label="区服">
				</el-table-column>
				<el-table-column prop="version_no" label="版本号">
				</el-table-column>
				<el-table-column prop="create_time" label="创建时间">
					<template slot-scope="scope">
						{{ formatDate(scope.row.create_time) }}
					</template>
				</el-table-column>
			</el-table>

			<el-pagination @current-change="handleCurrentChange" class="pagination" :current-page.sync="page_index"
				:page-size="page_size" layout="prev, pager, next, jumper" :total="total_count">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue';
	import protocol from '../../../js/protocol.js'
	export default {
		name: 'currencyLog',
		data() {
			return {

				appId: 0,

				server_id: "",

				select_time: [],

				cid2: "",

				cid3: "",


				tableData: [],

				type_list: {},

				type_list_tow: {},

				page_index: 1,

				page_size: 20,

				total_count: 0
			}
		},

		components: {
			sreachForm
		},

		mounted() {
			this.appId = this.$userData.curAppId;
			this.loadTypeList();
		},

		methods: {

			getAppName(id) {
				return this.$userData.getAppName(id);
			},

			async loadTypeList() {

				await this.$userData.initAppRand();

				let list1 = await this.$userData.GetCategoryList("money");

				if (list1) {
					for (let i = 0; i < list1[0].length; i++) {
						let data = list1[0][i];
						this.type_list[data["cid"]] = data["name"];
					}
				}

				if (list1) {
					for (let i = 0; i < list1[1].length; i++) {
						let data = list1[1][i];
						this.type_list_tow[data["cid"]] = data["name"];
					}
				}

				this.loadRechangerData();
			},

			async loadRechangerData() {
				let ret = await this.$http.post(protocol.get_money_report, {
					"app_id": this.appId,
					"page_index": this.page_index,
					"cid2": this.cid2,
					"cid3": this.cid3,
					"select_time": this.select_time,
					"server_id": this.server_id
				});

				if (ret) {
					this.tableData = ret.list;
					this.page_index = ret.page_index;
					this.page_size = ret.page_size;
					this.total_count = ~~ret.total_count;
				}
			},

			formatDate(timestamp) {
				const date = new Date(timestamp * 1000);
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');

				return `${year}-${month}-${day}`;
			},

			async sreachCurrencyLog(data) {

				this.appId = data.id;
				this.cid2 = data.cid2;
				this.cid3 = data.cid3;
				this.select_time = data.date;
				this.server_id = data.server_id;

				this.loadRechangerData();
			},

			handleCurrentChange(page) {
				this.page_index = page;
				this.loadRechangerData();
			}
		}
	}
</script>

<style lang="less">
	.currencyLog {
		.sreach_form {
			.el-select {
				width: 140px;
			}
		}
	}
</style>