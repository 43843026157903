<template>
	<div class="ltvPage">
		<sreachForm @sreach="showContent"></sreachForm>
		<div class="chart">
			<el-radio-group v-model="select_tag" class="select_tag" @change="changeSelectDayCount">
				<el-radio-button label="1">1天</el-radio-button>
				<el-radio-button label="2">2天</el-radio-button>
				<el-radio-button label="3">3天</el-radio-button>
				<el-radio-button label="4">4天</el-radio-button>
				<el-radio-button label="5">5天</el-radio-button>
				<el-radio-button label="6">6天</el-radio-button>
				<el-radio-button label="7">7天</el-radio-button>
				<el-radio-button label="14">14天</el-radio-button>
				<el-radio-button label="21">21天</el-radio-button>
				<el-radio-button label="30">30天</el-radio-button>
				<el-radio-button label="45">45天</el-radio-button>
				<el-radio-button label="90">90天</el-radio-button>
			</el-radio-group>
			<div id="retentionChart" ref="retentionChart">
				<loading v-if="isLoad"></loading>
			</div>
		</div>
		<div class="table">
			<el-table :data="tableData" height="250" border style="width: 100%">
				<el-table-column prop="日期" label="日期">
				</el-table-column>
				<el-table-column prop="创角数" label="创角数">
				</el-table-column>
				<el-table-column prop="1" label="第1天">
				</el-table-column>
				<el-table-column prop="2" label="第2天">
				</el-table-column>
				<el-table-column prop="3" label="第3天">
				</el-table-column>
				<el-table-column prop="4" label="第4天">
				</el-table-column>
				<el-table-column prop="5" label="第5天">
				</el-table-column>
				<el-table-column prop="6" label="第6天">
				</el-table-column>
				<el-table-column prop="7" label="第7天">
				</el-table-column>
				<el-table-column prop="14" label="第14天">
				</el-table-column>
				<el-table-column prop="21" label="第21天">
				</el-table-column>
				<el-table-column prop="30" label="第30天">
				</el-table-column>
				<el-table-column prop="45" label="第45天">
				</el-table-column>
				<el-table-column prop="90" label="第90天">
				</el-table-column>
			</el-table>

			<el-pagination @current-change="handleCurrentChange" class="pagination" :current-page.sync="page_index"
				:page-size="page_size" layout="prev, pager, next, jumper" :total="total_count">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue';
	import protocol from '../../../js/protocol.js'
	import loading from '../../../components/loading.vue';
	export default {
		name: 'ltvPage',

		components: {
			sreachForm,
			loading
		},

		data() {
			return {
				select_tag: "1",
				page_index: 1,
				page_size: 20,
				total_count: 0,
				tableData: [],
				select_time: "",
				isLoad: false,
			}
		},

		mounted() {
			this.showContent();
		},

		methods: {

			async showContent(data) {

				this.isLoad = true;

				if (data) {
					this.select_time = data.date;
				}

				await this.loadList();
				this.showRetentionChart();

				this.isLoad = false;
			},

			async loadList() {
				let res = {
					"app_id": this.$userData.curAppId,
					"page_index": this.page_index,
					"select_time": this.select_time == "" ? [] : this.select_time
				};
				let ret = await this.$http.post(protocol.ltv_report, res);
				if (ret) {

					let list = ret;
					this.tableData = [];
					for (let key in list) {
						let data = {};
						data['日期'] = key;
						data['创角数'] = list[key]['创角数'];
						for (let day in list[key].ltvs) {
							data[day] = list[key].ltvs[day];
						}
						this.tableData.push(data);
					}
				}
			},

			changeSelectDayCount() {
				this.showRetentionChart();
			},

			async showRetentionChart() {

				const days = [];
				const retentionRate = [];

				if (!this.$refs.retentionChart) {
					return;
				}

				const lineChart = this.$echarts.init(this.$refs.retentionChart);

				let keys = {
					"14": "14",
					"21": "21",
					"30": "30",
					"45": "45",
					"90": "90",
				};

				for (let num in 7) {
					let key = num + 1;
					keys[key] = key;
				}


				for (let data of this.tableData) {

					days.push(data['日期']);

					let v = data[keys[this.select_tag]];

					retentionRate.push(~~v);
				}

				const option = {
					title: {
						text: 'ltv总营收',
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						data: days
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value}'
						}
					},
					series: [{
						data: retentionRate,
						type: 'line',
						smooth: true,
						name: "ltv",
						lineStyle: {
							color: 'blue'
						},
						label: {
							show: true,
							position: 'top'
						}
					}]
				};

				lineChart.setOption(option);
			},

			handleCurrentChange(page) {
				this.page_index = page;
				this.loadList();
			}
		}
	}
</script>

<style lang="less">
	.ltvPage {
		padding-bottom: 30px;

		.chart {
			.select_tag {
				margin: 0;
				margin-bottom: 30px;
			}

			#retentionChart {
				height: 500px;
				position: relative;
			}
		}
	}
</style>