<template>
	<div class="userRetention">
		<sreachForm @sreach="showContent"></sreachForm>
		<div class="chart">
			<el-radio-group v-model="select_tag" class="select_tag" @change="changeSelectDayCount">
				<el-radio-button label="2">次日留存率</el-radio-button>
				<el-radio-button label="3">3日</el-radio-button>
				<el-radio-button label="4">4日</el-radio-button>
				<el-radio-button label="5">5日</el-radio-button>
				<el-radio-button label="6">6日</el-radio-button>
				<el-radio-button label="7">7日</el-radio-button>
				<el-radio-button label="15">15日</el-radio-button>
				<el-radio-button label="30">30日</el-radio-button>
				<el-radio-button label="60">60日</el-radio-button>
			</el-radio-group>
			<div style="position: relative;">
				<div id="retentionChart" ref="retentionChart"></div>
				<loading v-if="isLoad"></loading>
			</div>
		</div>
		<div class="table">
			<el-table :data="tableData" height="250" border style="width: 100%">
				<el-table-column prop="日期" label="日期">
				</el-table-column>
				<el-table-column prop="创角数" label="创角数">
				</el-table-column>
				<el-table-column prop="次日留存率" label="次日留存率">
				</el-table-column>
				<el-table-column prop="3日留存率" label="3日留存率">
				</el-table-column>
				<el-table-column prop="4日留存率" label="4日留存率">
				</el-table-column>
				<el-table-column prop="5日留存率" label="5日留存率">
				</el-table-column>
				<el-table-column prop="6日留存率" label="6日留存率">
				</el-table-column>
				<el-table-column prop="7日留存率" label="7日留存率">
				</el-table-column>
				<el-table-column prop="15日留存率" label="15日留存率">
				</el-table-column>
				<el-table-column prop="30日留存率" label="30日留存率">
				</el-table-column>
				<el-table-column prop="60日留存率" label="60日留存率">
				</el-table-column>
			</el-table>

			<el-pagination class="pagination" :current-page.sync="cur_page" :page-size="cur_size"
				layout="prev, pager, next, jumper" :total="0">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue';
	import protocol from '../../../js/protocol.js'
	import loading from '../../../components/loading.vue';
	export default {
		name: 'userRetention',

		components: {
			sreachForm,
			loading
		},

		data() {
			return {
				select_tag: "2",
				cur_page: 1,
				cur_size: 4,
				total: 15,
				tableData: [],
				select_time: "",
				isLoad: false,
			}
		},

		mounted() {
			this.showContent();
		},

		methods: {

			async showContent(data) {

				this.isLoad = true;

				if (data) {
					this.select_time = data.date;
				}

				await this.loadList();
				this.showRetentionChart();

				this.isLoad = false;
			},

			async loadList() {
				let res = {
					"app_id": this.$userData.curAppId,
					"cur_page": this.cur_page,
					"page_size": this.cur_size,
					"select_time": this.select_time == "" ? [] : this.select_time
				};
				let ret = await this.$http.post(protocol.user_retention, res);
				if (ret) {

					let list = ret;
					this.tableData = [];
					for (let key in list) {
						let data = list[key];
						this.tableData.push(data);
					}
				}
			},

			changeSelectDayCount() {
				this.showContent();
			},

			async showRetentionChart() {

				const days = [];
				const retentionRate = [];

				if (!this.$refs.retentionChart) {
					return;
				}

				const lineChart = this.$echarts.init(this.$refs.retentionChart);

				let keys = {
					"2": "次日留存率",
					"3": "3日留存率",
					"4": "4日留存率",
					"5": "5日留存率",
					"6": "6日留存率",
					"7": "7日留存率",
					"15": "15日留存率",
					"30": "30日留存率",
					"60": "60日留存率"
				};

				for (let data of this.tableData) {
					days.push(data['日期']);
					let v = data[keys[this.select_tag]];
					v = v.replace("%", "");
					retentionRate.push(~~v);
				}

				const option = {
					title: {
						text: '用户留存',
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						data: days
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value}'
						}
					},
					series: [{
						data: retentionRate,
						type: 'line',
						smooth: true,
						lineStyle: {
							color: 'blue'
						}
					}]
				};

				lineChart.setOption(option);
			}
		}
	}
</script>

<style lang="less">
	.userRetention {
		padding-bottom: 30px;

		.chart {
			.select_tag {
				margin: 0;
				margin-bottom: 30px;
			}

			#retentionChart {
				height: 500px;
			}
		}
	}
</style>